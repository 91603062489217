import { useSearchParams } from 'react-router-dom'
import { ChevronLeftIcon, ChevronRightIcon } from '../../icons'
import { translations } from '../../locales'
import { useContext } from 'react'
import { LocaleContext } from '../../contexts'

const Pagination = ({ items, prev, next, from, to, count, pageParam = 'page' }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { locale } = useContext(LocaleContext)

  const fetchRecords = (page) => {
    const queryParams = Object.fromEntries(searchParams)
    queryParams[pageParam] = page

    setSearchParams(queryParams)
  }

  return (
    <div className='flex gap-x-4 text-gray-400 text-sm mt-3'>
      <p>{translations[locale].helpers.rowsPerPage.text} {items}</p>

      <p>{from} - {to} {translations[locale].helpers.of.text} {count}</p>

      <div className='flex gap-x-2'>
        <button type='button' className='disabled:text-gray-300' disabled={!prev} onClick={() => { fetchRecords(prev) }}>
          <ChevronLeftIcon color='currentColor' />
        </button>
        <button type='button' className='disabled:text-gray-300' disabled={!next} onClick={() => { fetchRecords(next) }}>
          <ChevronRightIcon color='currentColor' />
        </button>
      </div>
    </div>
  )
}

export default Pagination
