import { useContext } from "react"
import { Header, LabelValue } from "../../../../../components"
import { translations } from "../../../../../locales"
import { LocaleContext, TenantTokenContext } from "../../../../../contexts"
import { useParams } from "react-router-dom"
import { useFetch } from "../../../../../hooks"
import { buildApiUrl, toCurrency } from "../../../../../utils"
import AdminSalesPresentationHistoricalPrices from "./HistoricalPrices"

const AdminSalesPresentation = () => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const { productId, salesPresentationId } = useParams()

  const { data } = useFetch(
    buildApiUrl(`/octopus_os/t/${tenantToken}/admin/products/${productId}/sales_presentations/${salesPresentationId}`)
  )
  const record = data?.data

  if (!record) return <p>Cargando...</p>

  return (
    <>
      <Header title={`${record.product_name} ${record.name}`}/>

      <div className="block md:flex md:justify-around md:gap-4">
        <LabelValue label={'SKU'} value={record.sku} className='mb-4 md:mb-0'/>
        <LabelValue label={translations[locale].attributes.salesPresentation.baseUnitPrice}
          value={toCurrency(record.unit_price)} className='mb-4 md:mb-0'/>
        <LabelValue label={translations[locale].attributes.salesPresentation.quantityValue}
          value={record.measure_quantity} className='mb-4 md:mb-0'/>
        <LabelValue label={translations[locale].attributes.salesPresentation.vendors}
          value={record.vendors} className='mb-4 md:mb-0'/>
      </div>

      <div className="mt-6">
        <AdminSalesPresentationHistoricalPrices />
      </div>
    </>
  )
}

export default AdminSalesPresentation;