const Header = ({ title, subtitle, children, className = '', fontSize = 'text-3xl' }) => {
  return (
    <div className={`flex justify-between items-center mb-4 ${className}`}>
      <div>
        <h1 className={`font-bold mt-0 ${fontSize}`}>{title}</h1>
        <p className='mt-0'>{subtitle}</p>
      </div>

      <div className='flex gap-3'>
        {children}
      </div>
    </div>
  )
}

export default Header
