import { useContext, useState } from "react";
import { Header, TertiaryLink, Button, Form, DateField } from "../../../components";
import { translations } from "../../../locales";
import { LocaleContext, TenantTokenContext } from "../../../contexts";
import { useNavigate } from "react-router-dom";
import { adminRoute } from "../../../routesHelpers";
import { buildApiUrl, hyphenizeDate } from "../../../utils";

const AdminNewCashClosing = () => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const [errors, setErrors] = useState({})
  const navigate = useNavigate()

  const onSuccessfulSubmit = (data) => {
    navigate(adminRoute('cashClosing', { id: data.id }))
  }

  return (
    <>
      <Header title={`${translations[locale].helpers.new.female.text} ${translations[locale].models.cashClosing.toLowerCase()}`}/>

      <Form
        url={buildApiUrl(`/octopus_os/t/${tenantToken}/admin/cash_closings/new`)}
        method='GET'
        onSuccessfulSubmit={onSuccessfulSubmit}
        onFailedSubmit={errors => { setErrors(errors) }}
      >
        <DateField method='date' label={'Fecha'} defaultValue={hyphenizeDate(new Date())} serverError={errors.date}/>
        
        <div className='flex justify-end gap-x-2 mt-4'>
          <TertiaryLink href={-1} className='text-gray-500 font-bold'>{translations[locale].helpers.cancel.text}</TertiaryLink>
          <Button type='submit'>{translations[locale].helpers.continue.text}</Button>
        </div>
      </Form>
    </>
  )
}

export default AdminNewCashClosing;
