import { useContext } from "react";
import { 
  Header, PrimaryLink, TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Pagination,
  Dropdown, DropdownItem
} from "../../../components";
import { translations } from "../../../locales";
import { LocaleContext, TenantTokenContext, TokenContext } from "../../../contexts";
import { adminRoute, apiBaseUrl } from "../../../routesHelpers";
import { useNavigate } from "react-router-dom";
import { useFetch } from '../../../hooks'
import { buildApiUrl } from "../../../utils";

const AdminCustomers = ({ updatedAt }) => {
  const { locale } = useContext(LocaleContext)
  const { token } = useContext(TokenContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const navigate = useNavigate()

  const baseURL = buildApiUrl(`/octopus_os/t/${tenantToken}/admin/customers`, { locale })
  const { data } = useFetch(baseURL ,[], [updatedAt])

  const destroyRecord = async(recordId) => {
    if (!window.confirm('¿Desea eliminar esta presentación de venta?')) return

    const headers = { 'Content-Type': 'application/json' }

    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }

    const response = await fetch(
      `${apiBaseUrl}/octopus_os/t/${tenantToken}/admin/customers/${recordId}`,
      { method: 'DELETE', headers: headers }
    )

    if (response.ok) {
      navigate(
        adminRoute('customers'),
        { state: { adminCustomers: { updatedAt: Date.now() } } }
      )
    }
  }

  return(
    <>
      <Header title={translations[locale].customers.title}>
        <PrimaryLink href={adminRoute('newCustomer')} modal>
          {translations[locale].helpers.new.male.text}
        </PrimaryLink>
      </Header>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className='font-bold' value='Nombre' />
              <TableCell className='font-bold' value='Razón Social' />
              <TableCell className='font-bold' value='RFC' />
              <TableCell className='font-bold' value='Correo' />
              <TableCell className='font-bold' value='Teléfono' />
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {
              data.data.map((record) => {
                return (
                  <TableRow key={record.id} className='hover:bg-slate-100 hover:cursor-pointer'>
                    <TableCell value={record.name} />
                    <TableCell value={record.legal_name} />
                    <TableCell value={record.rfc} />
                    <TableCell value={record.email} />
                    <TableCell value={record.phone} />
                    <TableCell>
                    <Dropdown>
                        <DropdownItem
                          name={`${translations[locale].helpers.edit.text}`}
                          href={adminRoute('editCustomer', { id: record.id })}
                          modal
                        />

                        <DropdownItem
                          name={`${translations[locale].helpers.delete.text}`}
                          className='text-red-500'
                          handleClick={() => destroyRecord(record.id)}
                        />
                      </Dropdown>
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-end'>
        { data.pagination && 
          <Pagination
            items={data.pagination.items} count={data.pagination.count} to={data.pagination.to}
            from={data.pagination.from} prev={data.pagination.prev} next={data.pagination.next}/>
        }
      </div>
    </>
  )
}

export default AdminCustomers;