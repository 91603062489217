export const InvoiceIcon = ({ color = 'currentColor' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="icon">
      <path fill={color} d="M64 0C29 0 0 29 0 64v384c0 35 29 64 64 64h256c35 0 64-29 64-64V160H256c-18 
        0-32-14-32-32V0H64zm192 0v128h128L256 0zM80 64h64c9 0 16 7 16 16s-7 16-16 16H80c-9 0-16-7-16-16s7-16 
        16-16zm0 64h64c9 0 16 7 16 16s-7 16-16 16H80c-9 0-16-7-16-16s7-16 16-16zm16 96h192c18 0 32 14 
        32 32v64c0 18-14 32-32 32H96c-18 0-32-14-32-32v-64c0-18 14-32 32-32zm0 32v64h192v-64H96zm144 
        160h64c9 0 16 7 16 16s-7 16-16 16h-64c-9 0-16-7-16-16s7-16 16-16z"/>
    </svg>
  )
}