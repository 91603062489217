import { Link, useLocation } from 'react-router-dom'
import { classNames } from '../../utils'

const SecondaryLink = ({ 
    children, title, href, modal = false, modalSize = '', modalHeight = '', className = '', 
    textSize = 'text-sm', textColor = 'text-blue-600', backgroundColor = 'bg-blue-100', 
    disabled = false, ...props 
}) => {
  const location = useLocation()
  const defaultClassName = ['flex justify-center items-center px-4 py-2 rounded-md', className, textSize]
  defaultClassName.push(disabled ? 'bg-gray-300' : backgroundColor)
  defaultClassName.push(disabled ? 'text-gray-500' : textColor)

  return (
    <Link
      className={classNames(defaultClassName.join(' '), { 'pointer-events-none': disabled })}
      to={href}
      state={modal ? { previousLocation: location, modalSize, modalHeight } : {}}
      {...props}
    >
      {children || title}
    </Link>
  )
}

export default SecondaryLink
