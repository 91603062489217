export const CashRegisterIcon = ({ color = 'currentColor' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="icon">
      <path fill={color} d="M64 0C46 0 32 14 32 32v64c0 18 14 32 32 32h80v32H87c-32 0-58 
        23-63 54L1 364l-1 14v70c0 35 29 64 64 64h384c35 0 64-29 64-64v-70l-1-14-23-150c-4-31-31-54-63-54H208v-32h80c18 
        0 32-14 32-32V32c0-18-14-32-32-32H64zm32 48h160c9 0 16 7 16 16s-7 16-16 16H96c-9 
        0-16-7-16-16s7-16 16-16zM64 432c0-9 7-16 16-16h352c9 0 16 7 16 16s-7 16-16 16H80c-9 
        0-16-7-16-16zm48-168a24 24 0 1 1 0-48 24 24 0 1 1 0 48zm120-24a24 24 0 1 1-48 0 
        24 24 0 1 1 48 0zm-72 104a24 24 0 1 1 0-48 24 24 0 1 1 0 48zm168-104a24 24 0 1 1-48 
        0 24 24 0 1 1 48 0zm-72 104a24 24 0 1 1 0-48 24 24 0 1 1 0 48zm168-104a24 24 0 1 1-48 
        0 24 24 0 1 1 48 0zm-72 104a24 24 0 1 1 0-48 24 24 0 1 1 0 48z"/>
    </svg>
  )
}