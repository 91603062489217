import { useContext } from "react";
import { Header } from "../../../components";
import { translations } from "../../../locales";
import { LocaleContext, TenantTokenContext } from "../../../contexts";
import AdminVendorForm from './VendorForm'
import { buildApiUrl } from "../../../utils";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch, useReferrer } from "../../../hooks";
import { adminRoute } from "../../../routesHelpers";

const AdminEditVendor = () => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const { referrer } = useReferrer()
  const { id } = useParams()
  const navigate = useNavigate()

  const { data } = useFetch(buildApiUrl(`/octopus_os/t/${tenantToken}/admin/vendors/${id}`))
  const record = data?.data

  const redirectToVendors = () => {
    navigate(referrer || adminRoute('vendors'), { state: { adminVendors: { updatedAt: Date.now() } } })
  }

  if (!record) return <p>Cargando...</p>

  return (
    <>
      <Header
        title={`${translations[locale].helpers.edit.text} ${translations[locale].models.vendor.toLowerCase()}`}/>
         
      <AdminVendorForm 
        url={buildApiUrl(`/octopus_os/t/${tenantToken}/admin/vendors/${id}`)}
        method='PUT'
        onSuccess={redirectToVendors}
        record={record}/>
    </>
  )
}

export default AdminEditVendor;