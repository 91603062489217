import './../../components/Navbar/Navbar.scss'
import { Navbar, Sidebar, SidebarItem, Snackbar } from '../../components'
import { Outlet } from 'react-router-dom'
import { adminRoute } from '../../routesHelpers'
import { translations } from '../../locales'
import { useContext } from 'react'
import { LocaleContext } from '../../contexts'
import { CashClosingIcon, CashRegisterIcon, ChartIcon, CupcakeIcon, DashboardIcon, InvoiceIcon, ReceiptIcon, TruckFieldIcon, UserTagIcon } from '../../icons'

const AdminLayout = () => {
  const { locale } = useContext(LocaleContext)

  return (
    <div className='admin flex flex-1 flex-col-reverse md:flex-row'>
      <Sidebar>
        <SidebarItem path={adminRoute('dashboard')} icon={<DashboardIcon/>} text={translations[locale].sidebar.dashboard}/>
        <SidebarItem path={adminRoute('customers')} icon={<UserTagIcon/>} text={translations[locale].sidebar.customers}/>
        <SidebarItem path={adminRoute('products')} icon={<CupcakeIcon/>} text={translations[locale].sidebar.products}/>
        <SidebarItem path={adminRoute('orders')} icon={<ReceiptIcon/>} text={translations[locale].sidebar.orders}/>
        <SidebarItem path={adminRoute('cashClosings')} icon={<CashClosingIcon/>} text={translations[locale].sidebar.cashClosings}/>
        <SidebarItem path={adminRoute('invoices')} icon={<InvoiceIcon/>} text={translations[locale].sidebar.invoices}/>
        <SidebarItem path={adminRoute('cashier')} icon={<CashRegisterIcon/>} text={translations[locale].sidebar.cashier}/>
        <SidebarItem path={adminRoute('metrics')} icon={<ChartIcon/>} text={translations[locale].sidebar.metrics}/>
        <SidebarItem path={adminRoute('vendors')} icon={<TruckFieldIcon/>} text={translations[locale].sidebar.vendors}/>
      </Sidebar>

      <div className='main-content flex flex-1 flex-col'>
        <Navbar />

        <Snackbar/>

        <div className='flex flex-1 flex-col items-center bg-slate-100 p-8 md:p-16 pb-32 md:pb-16'>
          <div className='flex flex-col flex-1 max-w-6xl w-full'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminLayout
