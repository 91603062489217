import { useFormContext, Controller } from 'react-hook-form'
import { methodToDomId } from '../../utils'
import Select from 'react-select'
import { useFetch } from '../../hooks/useFetch'

const SelectField = ({
  fetchOptionsUrl,
  method,
  errorMessage,
  defaultValue,
  serverError,
  defaultOptions = [],
  label = '',
  fieldClass = '',
  labelClass = '',
  disabled = false,
  ...props
}) => {
  const { control } = useFormContext()
  const id = methodToDomId(method)
  const { data } = useFetch(fetchOptionsUrl, defaultOptions)
  const options = data.data

  return (
    <Controller
      control={control}
      name={method}
      defaultValue={defaultValue?.value}
      shouldUnregister
      render={({
        field: { onChange, onBlur, ref },
        fieldState: { error }
      }) => (
        <div className={`field ${fieldClass}`}>
          {label && <label htmlFor={id} className={`label ${labelClass}`}><b>{label}</b></label>}

          <Select
            id={id}
            className='react-select-container'
            classNamePrefix='react-select'
            defaultValue={defaultValue}
            inputRef={ref}
            onBlur={onBlur}
            onChange={(value) => { onChange(value?.value) }}
            options={options}
            menuPortalTarget={document.body}
            isDisabled={disabled}
            {...props}
          />
          {(error || serverError) && <p className='text-red-500 text-sm'>{error?.message || serverError}</p>}
        </div>
      )}
    />
  )
}

export default SelectField
