import { useContext } from "react";
import { useFetch } from "../../../hooks";
import { buildApiUrl } from "../../../utils";
import { LocaleContext, TenantTokenContext } from "../../../contexts";
import { Header } from "../../../components";
import { translations } from "../../../locales";
import AdminCashierSavedOrder from "./SavedOrder";

const AdminCashierOrders = () => {
  const { tenantToken } = useContext(TenantTokenContext)
  const { locale } = useContext(LocaleContext)

  const { data, setData } = useFetch(buildApiUrl(`/octopus_os/t/${tenantToken}/pos/orders`, { locale }))

  const onDestroyed = (orderToken) => {
    setData({ data: data.data.filter(item => item.token !== orderToken)})
  } 

  if (!data?.data) return <p>Cargando...</p>

  return (
    <>
      <Header title={translations[locale].cashierOrders.title}/>

      <div className="flex flex-col items-center md:grid md:grid-cols-3 gap-4">
        {
          data.data.map(record => {
            return <AdminCashierSavedOrder key={record.id} order={record} onDestroyed={onDestroyed}/>
          })
        }
      </div>
    </>
  )
}

export default AdminCashierOrders;