import { useEffect, useRef } from "react"
import { Chart as ChartJS, registerables } from "chart.js"

ChartJS.register(...registerables);

const Chart = ({ labels = [], datasets = [], type = 'line', options = {} }) => {
  const canvasRef = useRef()
  const chartRef = useRef()

  useEffect(() => {
    if (!canvasRef.current) return

    options['animation'] = true
    options['responsive'] = true
    chartRef.current = new ChartJS(canvasRef.current, {
      type, data: { labels, datasets }, options, 
    });

    return () => {
      chartRef.current?.destroy()
      chartRef.current = null
    }
  }, [labels, datasets, type, options])

  return (
    <canvas ref={canvasRef} role='img' width='100%'/>
  )
}

export default Chart;