import { useFormContext } from "react-hook-form"
import { LoadingIcon } from "../../icons"
import { classNames } from "../../utils"

const Button = ({ 
  children, title, type = 'button', onClick = null, className = '', textSize='text-sm',
  textColor = 'text-white', backgroundColor = 'bg-blue-500', disabled = false, padding = 'px-4 py-2', rounded ='rounded-md',
  isLoading = false, ...props
}) => {
  const methods = useFormContext()
  const isSubmitting = methods?.formState?.isSubmitting || false
  const defaultClassName = ['flex justify-center items-center', rounded, padding,  className, textSize]
  defaultClassName.push(disabled ? 'bg-gray-300' : backgroundColor)
  defaultClassName.push(disabled ? 'text-gray-500' : textColor)

  return (
    <button
      className={classNames(defaultClassName.join(' '), { 'pointer-events-none': disabled })}
      type={type}
      onClick={(disabled || isLoading || isSubmitting) ? () => {} : onClick}
      {...props}
    >
      { 
        (isSubmitting || isLoading) ? 
          <LoadingIcon />
        :
          children || title
      }
    </button>
  )
}

export default Button
