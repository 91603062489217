const setStoredLocation = (path) => {
  sessionStorage.setItem('storedLocation', path)
}

const getStoredLocation = () => {
  sessionStorage.getItem('storedLocation')
}

const clearStoredLocation = () => {
  sessionStorage.removeItem('storedLocation')
}

const classNames = (baseClass = '', optionalClasses = {}) => {
  const classes = [baseClass]

  for (const [candidateClass, value] of Object.entries(optionalClasses)) {
    if (value === true) {
      classes.push(candidateClass)
    }
  }

  return classes.join(' ').trim()
}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const methodToDomId = (text) => {
  return text.replace('.', '_').split('_').map(t => capitalizeFirstLetter(t)).join('')
}

const toCurrency = (value) => {
  return `$${parseFloat(value).toFixed(2)}`
}

const toPercentage = (value) => {
  const formattedValue = parseInfinityFloat(value)
  if(formattedValue === Infinity) {
    return '+ %'
  } else if (formattedValue === -Infinity) {
    return '- %'
  }

  return `${formattedValue.toFixed(2)} %`
}

const isElectron = () => {  
  const electronWindow = window?.process?.versions?.electron

  return electronWindow !== undefined && electronWindow !== null;
}

const buildApiUrl = (path, queryParams = {}) => {
  const url = new URL(path, process.env.REACT_APP_API_BASE_URL)
  for (const [queryParam, value] of Object.entries(queryParams)) {
    if (value !== undefined && value !== null) {
      url.searchParams.append(queryParam, value)
    }
  }

  return url.href
}

const buildQueryString = (obj, prefix = '') => {
  const queryString = Object.keys(obj).map((key) => {
    const value = obj[key];
    const prefixedKey = prefix ? `${prefix}[${key}]` : key;

    if (value !== null && typeof value === 'object') {
      // Recursively build query string for nested object
      return buildQueryString(value, prefixedKey);
    } else {
      // Encode key and value, and return as key=value
      return `${encodeURIComponent(prefixedKey)}=${encodeURIComponent(value)}`;
    }
  });

  // Join each key=value pair with '&'
  return queryString.join('&');
}

const parseInfinityFloat = (value) => {
  if (value === 'Infinity') {
    return Infinity
  } else if (value === '-Infinity') {
    return -1 * Infinity
  } else {
    return parseFloat(value)
  }
}

const hyphenizeDate = (date) => {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = (date.getDate()).toString().padStart(2, '0')

  return `${year}-${month}-${day}`;
}

export {
  setStoredLocation, getStoredLocation, clearStoredLocation, classNames, methodToDomId, toCurrency,
  isElectron, buildApiUrl, buildQueryString, toPercentage, parseInfinityFloat, hyphenizeDate
}
