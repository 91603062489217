import { useContext } from 'react';
import { Dropdown, DropdownItem, Header, Pagination, PrimaryLink, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '../../../components'
import { translations } from '../../../locales';
import { LocaleContext, TenantTokenContext, TokenContext } from '../../../contexts';
import { useFetch } from '../../../hooks';
import { buildApiUrl } from '../../../utils';
import { adminRoute } from '../../../routesHelpers';
import { useNavigate, useSearchParams } from 'react-router-dom';

const AdminVendors = ({ updatedAt }) => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const { token } = useContext(TokenContext)
  const [searchParams, ] = useSearchParams()
  const navigate = useNavigate()

  const url = buildApiUrl(`/octopus_os/t/${tenantToken}/admin/vendors`,  { ...Object.fromEntries(searchParams), locale })
  const { data } = useFetch(url, null, [updatedAt])
  const records = data?.data

  const destroyRecord = async (id) => {
    if (!window.confirm('¿Desea eliminar este proveedor?')) return

    fetch(
      buildApiUrl(`/octopus_os/t/${tenantToken}/admin/vendors/${id}`),
      { method: 'DELETE', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } }
    ).then(response => response.json())
    .then(() => {
      navigate(adminRoute('vendors'), { state: { adminVendors: { updatedAt: Date.now() } } })
    })
    .catch(() => {})   
  }

  if (!records) return <p>Cargando ...</p>

  return (
    <>
      <Header title={translations[locale].vendors.title}>
        <PrimaryLink href={adminRoute('newVendor')} modal>
          {translations[locale].helpers.new.male.text}
        </PrimaryLink>
      </Header>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>Nombre</b></TableCell>
              <TableCell><b>RFC</b></TableCell>
              <TableCell><b>Dirección</b></TableCell>
              <TableCell><b>Correo</b></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {
              records.map(record => {
                return (
                  <TableRow key={record.id}>
                    <TableCell>{record.name}</TableCell>
                    <TableCell>{record.rfc}</TableCell>
                    <TableCell>{record.address}</TableCell>
                    <TableCell>{record.email}</TableCell>
                    <TableCell className='is-narrow'>
                      <Dropdown>
                        <DropdownItem
                          name={`${translations[locale].helpers.edit.text}`}
                          href={adminRoute('editVendor', { id: record.id })}
                          modal
                        />

                        <DropdownItem
                          name={`${translations[locale].helpers.delete.text}`}
                          className='text-red-500'
                          handleClick={() => destroyRecord(record.id)}
                        />
                      </Dropdown>
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-end'>
        { data.pagination && 
          <Pagination
            items={data.pagination.items} count={data.pagination.count} to={data.pagination.to}
            from={data.pagination.from} prev={data.pagination.prev} next={data.pagination.next} />
        }
      </div>
    </>
  )
}

export default AdminVendors;