import { useContext } from 'react'
import { 
  Header, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination
} from '../../../components'
import { useFetch } from '../../../hooks/useFetch'
import { LocaleContext, TenantTokenContext } from '../../../contexts'
import { buildApiUrl } from '../../../utils'
import { useSearchParams } from 'react-router-dom'
import { translations } from '../../../locales'
import AdminOrderTableRow from './OrderTableRow'

const AdminOrders = ({ updatedAt }) => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const [searchParams] = useSearchParams()
  
  const url = buildApiUrl(`/octopus_os/t/${tenantToken}/admin/orders`, { ...Object.fromEntries(searchParams), locale })
  const { data } = useFetch(url, [], [updatedAt])

  return (
    <>
      <Header title={translations[locale].orders.title}/>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>Pedido</b></TableCell>
              <TableCell><b>Cliente</b></TableCell>
              <TableCell><b>Fecha de orden</b></TableCell>
              <TableCell><b>Facturado</b></TableCell>
              <TableCell className='text-right'><b>Precio</b></TableCell>
              <TableCell className='text-right'><b>Descuento</b></TableCell>
              <TableCell className='text-right'><b>Total</b></TableCell>
              <TableCell className='text-right'></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            { data.data.map(record => {
              return(
                <AdminOrderTableRow key={`${record.token}-${record.updated_at}`} orderItem={record}/>
              )
            }) }
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-end'>
        { data.pagination && 
          <Pagination
            items={data.pagination.items} count={data.pagination.count} to={data.pagination.to}
            from={data.pagination.from} prev={data.pagination.prev} next={data.pagination.next}/>
        }
      </div>
    </>
  )
}

export default AdminOrders
