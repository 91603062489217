import { classNames } from "../../utils";
import { TimesIcon } from "../../icons";
import { useContext, useEffect } from "react";
import { SnackbarContext } from "../../contexts";

const Snackbar = () => {
  const { snackbar, setSnackbar } = useContext(SnackbarContext)

  const type = snackbar?.type || 'notice'
  const className = classNames(
    'fixed top-4 left-1/2 -translate-x-1/2 px-4 py-2 rounded-md shadow-sm', 
    { 
      'bg-green-100 text-green-600': type === 'notice',
      'bg-yellow-100 text-yellow-600': type === 'warning', 
      'bg-red-100 text-red-600': type === 'alert'
    }
  )

  const closeSnackbar = () => {
    setSnackbar(null)
  }

  useEffect(() => {
    setTimeout(closeSnackbar, 5000)
  }, [snackbar])

  if (!snackbar || !snackbar.message) return

  return (
    <div className={`snackbar flex items-center ${className}`}>
      <p>{snackbar.message}</p>

      <button type="submit" className="ml-3" onClick={closeSnackbar}>
        <TimesIcon />
      </button>
    </div>
  )
}

export default Snackbar;