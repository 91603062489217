import { useContext } from 'react'
import { LocaleContext, TenantTokenContext } from '../../../contexts'
import { translations } from '../../../locales'
import { adminRoute } from '../../../routesHelpers'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Header, PrimaryLink, TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Table,
  Pagination,
  SearchBar
} from '../../../components'
import { useFetch } from '../../../hooks/useFetch'
import { buildApiUrl } from '../../../utils'

const AdminProducts = ({ updatedAt }) => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const url = buildApiUrl(`/octopus_os/t/${tenantToken}/admin/products`, { ...Object.fromEntries(searchParams), locale })
  const { data } = useFetch(url ,[], [updatedAt])

  return (
    <>
      <Header title={translations[locale].products?.title}>
        <PrimaryLink href={adminRoute('newProduct')} modal>
          {translations[locale].helpers.new.male.text}
        </PrimaryLink>
      </Header>

      <div className='mb-5'>
        <SearchBar url={url} method={'q.name_i_cont'}/>
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className='font-bold' value='Nombre' />
              <TableCell className='font-bold' value='Unidad' />
            </TableRow>
          </TableHead>

          <TableBody>
            {
              data.data.map((record) => {
                return (
                  <TableRow
                    key={record.id} className='hover:bg-slate-100 hover:cursor-pointer'
                    onDoubleClick={() => { navigate(adminRoute('showProduct', { productId: record.id })) }}
                  >
                    <TableCell value={record.name} />
                    <TableCell value={record.unit} />
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-end'>
        { data.pagination && 
          <Pagination
            items={data.pagination.items} count={data.pagination.count} to={data.pagination.to}
            from={data.pagination.from} prev={data.pagination.prev} next={data.pagination.next}/>
        }
      </div>
    </>
  )
}

export default AdminProducts
