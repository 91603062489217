import { useContext } from "react";
import { Button, Form } from "../../../components"
import { FloppyDiskIcon } from "../../../icons";
import { buildApiUrl } from "../../../utils";
import { SnackbarContext, TenantTokenContext } from "../../../contexts";
import { useNavigate } from "react-router-dom";
import { adminRoute } from "../../../routesHelpers";

const AdminCashierSaveOrder = ({ orderItemsCount }) => {
  const { tenantToken } = useContext(TenantTokenContext)
  const navigate = useNavigate()
  const { setSnackbar } = useContext(SnackbarContext)

  const onSuccessfulSubmit = (data) => {
    navigate(adminRoute('cashier'),  { state: { order: data } })
  }

  const onFailedSubmit = (errors) => {
    setSnackbar({ message: Object.values(errors).join('. '), type: 'alert' })
  }

  return (
    <Form
      url={buildApiUrl(`/octopus_os/t/${tenantToken}/pos/orders`)}
      method='POST'
      onSuccessfulSubmit={onSuccessfulSubmit}
      onFailedSubmit={onFailedSubmit}
    >
      <Button type='submit' className="h-full" textColor = 'text-blue-600' backgroundColor = 'bg-blue-100' 
        disabled={!orderItemsCount || orderItemsCount <= 0}
      >
        <FloppyDiskIcon />
      </Button>
    </Form>
  )
}

export default AdminCashierSaveOrder;