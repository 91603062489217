import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks";
import { useContext, useState } from "react";
import { LocaleContext, TenantTokenContext } from "../../../contexts";
import { Header, Button, TextField, Form } from "../../../components";
import { buildApiUrl, toCurrency } from "../../../utils";
import { translations } from "../../../locales";
import { adminRoute } from "../../../routesHelpers";

const AdminCashierOrderPayment = () => {
  const { orderToken } = useParams()
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const [errors, setErrors] = useState()
  const navigate = useNavigate()

  const paymentUrl = buildApiUrl(`/octopus_os/t/${tenantToken}/pos/orders/${orderToken}/payments`, { locale })
  const url = buildApiUrl(`/octopus_os/t/${tenantToken}/pos/orders/${orderToken}`, { locale })
  const { data } = useFetch(url, {})
  const order = data.data

  const onSuccessfulSubmit = (data) => {
    navigate(
      adminRoute('cashierOrderCompleted', { orderToken: data.token }), 
      { state: { total: data.total, amountReceived: data.amount_received } }
    )
  }

  return (
    <>
      <Header title='Cantidad a pagar' className="text-neutral-500"/>

      <div className="text-center text-7xl my-12">
        {toCurrency(order.total)} MXN
      </div>

      <Header title='Cliente me paga con' className="text-neutral-500"/>

      <Form method='POST' 
        url={paymentUrl} 
        onSuccessfulSubmit={onSuccessfulSubmit}
        onFailedSubmit={(errors) => { setErrors(errors) }}
      >
        <div className="flex items-center text-7xl">
          <p className="mr-4">$</p>

          <TextField
            method='octopus_os_shop_pay_order_form.amount_received'
            type="number"
            step={0.1} min={0}
            defaultValue={order?.amountReceived} 
            validates= {{ required: 'No puede estar en blanco' }}
            validate={{ greaterThanTotal: (v) => parseInt(v || 0) >= order.total || 'Debe ser mayor que la cantida a pagar' }}
            autoFocus
            serverError={errors?.amount_received}/>

          <p className="ml-4">MXN</p>
        </div>

        <div className="flex flex-col gap-4">
          <Button type='submit' className="is-fullwidth mt-16">
            {translations[locale].helpers.charge.text}
          </Button>
        </div>
      </Form>
    </>
  )
}

export default AdminCashierOrderPayment;