import './Sidebar.scss'

const Sidebar = ({ children }) => {
  return (
    <div className='sidebar bg-slate-900 py-4 md:py-8 px-4'>
      <div className='links-container flex top-8 gap-3'>
        {children}
      </div>
    </div>
  )
}

export default Sidebar
