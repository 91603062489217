import { useListItem } from '@floating-ui/react'
import { useContext } from 'react'
import { DropdownContext } from './DropdownContext'
import { Link, useLocation } from 'react-router-dom'
import { classNames } from '../../utils'

const DropdownItem = ({ href, name, disabled = false, handleClick = null, textColor = 'text-gray-700', className = '', modal = false, modalSize = '' }) => {
  const dropdown = useContext(DropdownContext)
  const item = useListItem()
  const isActive = item.index === dropdown.activeIndex
  const location = useLocation()

  const defaultClassName = ['dropdown-item block px-4 py-2 text-sm hover:bg-slate-100 outline-none', className]
  defaultClassName.push(disabled ? 'bg-gray-100 text-gray-400' : textColor)

  if (handleClick) {
    return (
      <Link
        className={classNames(defaultClassName.join(' '), { 'pointer-events-none': disabled })}
        ref={item.ref}
        tabIndex={isActive ? 0 : -1}
        onClick={handleClick}
      >
        {name}
      </Link>
    )
  } else {
    return (
      <Link
        className={classNames(defaultClassName.join(' '), { 'pointer-events-none': disabled })}
        ref={item.ref}
        tabIndex={isActive ? 0 : -1}
        to={href}
        state={ modal ? { previousLocation: location, modalSize } : {}}
      >
        {name}
      </Link>
    )
  }
}

export default DropdownItem
