import { useNavigate, useParams } from "react-router-dom";
import { Form, Header, SelectField, TertiaryLink, Button } from "../../../components";
import { buildApiUrl } from "../../../utils";
import { useContext, useState } from "react";
import { LocaleContext, TenantTokenContext } from "../../../contexts";
import { translations } from "../../../locales";
import { useReferrer } from "../../../hooks";
import { adminRoute } from "../../../routesHelpers";

const AdminInvoiceCancelation = () => {
  const { invoiceId } = useParams()
  const { tenantToken } = useContext(TenantTokenContext)
  const { locale } = useContext(LocaleContext)
  const { referrer } = useReferrer()
  const [errors, setErrors] = useState({})
  const navigate = useNavigate()

  const onSuccessfulSubmit = () => {
    navigate(referrer || adminRoute('invoices'), { state: { adminInvoices: { updatedAt: Date.now() } } })
  }

  return (
    <div>
      <Header title={'Motivo de cancelación'}/>

      <Form
        method='DELETE'
        url={buildApiUrl(`/octopus_os/t/${tenantToken}/admin/invoices/${invoiceId}`)}
        onSuccessfulSubmit={onSuccessfulSubmit}
        onFailedSubmit={(data) => setErrors(data)}
      >
        <SelectField
          method='octopus_os_shop_invoice.cancellation_motive_code'
          fieldClass='flex-1 mb-4' 
          fetchOptionsUrl={buildApiUrl(`/octopus_os/t/${tenantToken}/admin/invoices/cancelation_motives`)}
          serverError={errors.motive_code}
          required isSearchable/>

        <div className='flex justify-end gap-x-2'>
          <TertiaryLink href={-1} className='text-gray-500 font-bold'>{translations[locale].helpers.cancel.text}</TertiaryLink>
          <Button type='submit'>{translations[locale].helpers.save.text}</Button>
        </div>
      </Form>
    </div>
  )
}

export default AdminInvoiceCancelation;