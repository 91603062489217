import { 
  Header, TableContainer, Table, TableHead, TableCell, TableBody, TableRow,
  BooleanIcon, Pagination
} from "../../../components"
import { useFetch } from "../../../hooks"
import { adminRoute } from "../../../routesHelpers"
import { toCurrency } from "../../../utils"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

const OrdersTable = ({ title, url, updatedAt, pageParam = 'page' }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { data } = useFetch(url, null, [searchParams, updatedAt])
  const records = data.data

  if (!records) return <p>Cargando...</p>

  return (
    <>
      <Header title={title} fontSize="text-2xl"/> 
      
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>Pedido</b></TableCell>
              <TableCell><b>Cliente</b></TableCell>
              <TableCell><b>Fecha de orden</b></TableCell>
              <TableCell><b>Facturado</b></TableCell>
              <TableCell className='text-right'><b>Precio</b></TableCell>
              <TableCell className='text-right'><b>Descuento</b></TableCell>
              <TableCell className='text-right'><b>Total</b></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            { records.map(record => {
              return(
                <TableRow key={record.id} className='hover:bg-slate-100 hover:cursor-pointer'
                onDoubleClick={() => { navigate(adminRoute('showOrder', { orderToken: record.token }), { state: { previousLocation: location, modalSize: 'is-extra-large' }}) }}>
                <TableCell>{record.display_number}</TableCell>
                <TableCell>{record.customer_name}</TableCell>
                <TableCell>{record.ordered_at}</TableCell>
                <TableCell><BooleanIcon value={record.is_invoiced}/></TableCell>
                <TableCell className='text-right'>{toCurrency(record.price)}</TableCell>
                <TableCell className='text-right'>{toCurrency(record.discount_amount)}</TableCell>
                <TableCell className='text-right'>{toCurrency(record.total)}</TableCell>
              </TableRow>
              )
            }) }
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-end'>
        { data.pagination && 
          <Pagination
            items={data.pagination.items} count={data.pagination.count} to={data.pagination.to}
            from={data.pagination.from} prev={data.pagination.prev} next={data.pagination.next}
            pageParam={pageParam}/>
        }
      </div>
    </>
  )
}

export default OrdersTable